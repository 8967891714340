<template>
	<div class="voicePlayer" @click="handlePlay">
		<div class="wave">
			<div class="wifi-symbol">
				<div class="wifi-circle first"></div>
				<div :class="{ 'wifi-circle': true, second: true, play: status }"></div>
				<div :class="{ 'wifi-circle': true, third: true, play: status }"></div>
			</div>
		</div>
		<div class="time">{{ formatterTime }}</div>
	</div>
</template>

<script>
import BenzAMRRecorder from 'benz-amr-recorder';
export default {
	props: ['url', 'time'],
	data() {
		return {
			status: false,
			amr: null
		};
	},
	computed: {
		formatterTime() {
			var m = parseInt((this.time % 3600) / 60) || '';
			var s = parseInt((this.time % 3600) % 60) || '';
			return `${m ? m + `'` : ''}${s ? s + `"` : ''}`;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.amr = new BenzAMRRecorder();
			this.amr.initWithUrl(this.url);
		});
	},
	methods: {
		handlePlay() {
			if (this.status) {
				this.amr.stop();
				return false;
			} else {
				this.status = true;
				this.amr.play();
				this.amr.onEnded(() => {
					this.status = false;
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.voicePlayer {
	position: relative;
	width: 90px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid #f0f2f5;
	border-radius: 2px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	cursor: pointer;
	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		top: 50%;
		left: -6px;
		transform: translateY(-50%);
		border-color: transparent;
		border-style: solid;
		border-width: 6px;
		border-left-width: 0;
		border-right-color: #fff;
	}
	.time {
		position: absolute;
		bottom: 5px;
		left: 95px;
		color: #999;
		font-size: 12px;
	}
	.wave {
		box-sizing: border-box;
		position: absolute;
		transform: scale(0.5);
		top: -8px;
		left: 8px;
		.wifi-symbol {
			width: 50px;
			height: 50px;
			box-sizing: border-box;
			overflow: hidden;
			transform: rotate(135deg);
			.wifi-circle {
				border: 5px solid #999999;
				border-radius: 50%;
				position: absolute;
			}

			.first {
				width: 5px;
				height: 5px;
				background: #cccccc;
				top: 45px;
				left: 45px;
			}

			.second {
				width: 25px;
				height: 25px;
				top: 35px;
				left: 35px;
			}

			.third {
				width: 40px;
				height: 40px;
				top: 25px;
				left: 25px;
			}
			.play {
				animation: play 1s infinite 0.2s;
			}
		}
	}
}
</style>
<style>
@keyframes play {
	0% {
		opacity: 0;
		/*初始状态 透明度为0*/
	}

	100% {
		opacity: 1;
		/*结尾状态 透明度为1*/
	}
}
</style>
